body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@keyframes forward {
    0% {
        transform: translateX(-1306px);
    }
    100% {        
        transform: translateX(0);        
    }
}
@keyframes backward {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-1306px);        
    }
}
@keyframes slideOut {
    0% {        
        transform: translateX(0px);
    }
    100% {
        transform: translateX(-100vw);
        visibility: hidden;
    }
}
@keyframes slideIn {
    0% {
        visibility: visible;        
        transform: translateX(100vw);        
    }
    100% {        
        transform: translateX(0px);
    }
}

.leaflet-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

/* Works on Firefox */

/* Works on Chrome, Edge, and Safari */
* {
    scrollbar-width: thin;
    scrollbar-color: #c5c5c5ab rgba(0, 0, 0, 0.123);
}
@media (min-width: 640px) {
    * {
        scrollbar-width: thin;
        scrollbar-color: #0c3a2d rgba(0, 0, 0, 0.123);
    }
    *::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    *::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.123);
    }

    *::-webkit-scrollbar-thumb {
        background-color: #0c3a2d;
    }
}
